<template>
  <el-dialog :visible.sync="modelDialogVisible"
             width="700px"
             :title="moduleTitle"
             custom-class="module-dialog">
    <el-form class="module-dialog-form"
             label-width="80px"
             :model="form"
             ref="form">
      <el-form-item label="模块"
                    prop="classification"
                    :rules="[{ required: true, message: '请选择模块', trigger: 'change' }]">
        <v-select :disabled="isEdit"
                  v-model="form.classification"
                  :options="worktopModuleOps"
                  :width="width"
                  @change="moduleTypeChange"></v-select>
      </el-form-item>
      <el-form-item label="模块名称"
                    :rules="[{ required: true, message: '请输入模块名称', trigger: 'blur' }]"
                    :maxlength="10"
                    prop="modelAlias">
        <v-input v-model="form.modelAlias"
                 :width="width"
                 :maxlength="10"></v-input>
      </el-form-item>
      <el-form-item label="应用包"
                    v-if="isAppPackage && hasChildren"
                    prop="subModels"
                    :rules="[{ required: isAppPackage && hasChildren, validator: appPackageValid, trigger: 'change' }]">
        <chosenListPanel class="appPackage"
                         key='refId'
                         textAs="modelAlias"
                         :list.sync="form.subModels"
                         @select="toSelectApp"></chosenListPanel>
        <multi-select title="选择应用包"
                      :isShow.sync="selectAppPackageShow"
                      :showAllSelectBtn="false"
                      :searchUrl="getAppPackageListURL"
                      :headers="appPackageHeader"
                      :searchParams="appPackageSearchParams"
                      :responseParams="appPackageResponseParams"
                      :responseKey="appPackageResponseKey"
                      :backFill.sync="form.subModels"
                      :append-to-body="true"
                      :beforeSave="beforeSave">
          <template #searchSlot>
            <v-input label="应用包名称"
                     :width="160"
                     v-model="appPackageSearchParams.appPackageName"></v-input>
            <v-input label="创建人"
                     :width="160"
                     v-model="appPackageSearchParams.creater"></v-input>
            <v-datepicker label="创建时间段"
                          :startTime.sync="appPackageSearchParams.createTimeStart"
                          :endTime.sync="appPackageSearchParams.createTimeEnd"
                          :appendToBody="false"
                          format="YYYY-MM-DD HH:mm:ss"
                          type="rangedatetimer" />
          </template>
        </multi-select>
      </el-form-item>
      <el-form-item label="显示内容"
                    v-if="!isAppPackage && hasChildren"
                    required>
        <draggable class="sub-list-group"
                   tag="ul"
                   :list="allModelItems"
                   :options="dragOptions">
          <li class="sub-list-item"
              v-for="(sub,index) in allModelItems"
              :key="index">
            <v-checkbox :label="sub.modelAlias"
                        v-model="sub.checked"></v-checkbox>
          </li>
        </draggable>
      </el-form-item>
    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <v-button type="default"
                text="取 消"
                @click="modelDialogVisible=false"></v-button>
      <v-button type="success"
                style="margin-left:10px"
                @click="saveModule"
                text="保 存"></v-button>
    </div>
  </el-dialog>
</template>

<script>
import draggable from 'vuedraggable'
import { setWorktopModuleOps } from '../module'
import { MultiSelect, ChosenListPanel } from 'components/bussiness'
import { vCheckbox } from 'components/control'
import { getAllModelItemsURL, createModelURL, updateModelURL } from '../api'
export default {
  name: 'module-dialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    updateList: Function,
    appModule: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      width: 300,
      modelDialogVisible: this.value,
      moduleTitle: '编辑模块',
      submitURL: createModelURL,
      hasChildren: false,
      isAppPackage: false,
      dragOptions: {
        group: 'subModule',
        ghostClass: 'sub-ghost'
      },
      worktopModuleOps: setWorktopModuleOps(),
      allModelItems: [],
      form: {
        id: undefined,
        modelAlias: '',
        classification: undefined,
        subModels: []
      },
      getAppPackageListURL: `${API_CONFIG.butlerBaseURL}workPackage/choose/list`,
      selectAppPackageShow: false,
      appPackageHeader: [
        {
          prop: 'appPackageName',
          label: '应用包名称'
        },
        {
          prop: 'contentMenus',
          label: '包含菜单'
        },
        {
          prop: 'creater',
          label: '创建人'
        },
        {
          prop: 'createTime',
          label: '创建时间'
        }
      ],
      appPackageResponseKey: {
        id: 'refId',
        name: 'modelAlias'
      },
      appPackageResponseParams: {
        id: 'id',
        name: 'appPackageName'
      },
      appPackageSearchParams: {
        appPackageName: '',
        creater: '',
        createTimeStart: '',
        createTimeEnd: ''
      }
    }
  },
  created () {
    // this.submitURL = this.isEdit ? updateModelURL : createModelURL
    this.moduleTitle = this.isEdit ? '编辑模块' : '新增模块'
    let userType = this.$store.state.userInfo.userType
    // 当为项目账号才能添加空间检索模块
    if(userType !== '101'){
      let index = this.worktopModuleOps.findIndex(item=>item.value=="spatialRetrieval")
      this.worktopModuleOps.splice(index,1)
    }
  },
  components: {
    MultiSelect,
    ChosenListPanel,
    vCheckbox,
    draggable
  },
  methods: {
    // 查询模块
    getAllModelItems (classification) {
      let _this_ = this
      let postData = {
        classification: classification,
        id: this.form.id
      }
      _this_.allModelItems = []
      _this_.form.subModels = []
      _this_.$axios.get(getAllModelItemsURL, { params: postData }).then(res => {
        if (res.status === 100) {
          _this_.form.modelAlias = res.data.modelAlias
          const data = res.data.childreds
          if (data && data.length) {
            if (classification === 'commonFunctions') {
              _this_.form.subModels = data.map(item => {
                let obj = {}
                obj.refId = item.refId
                obj.modelAlias = item.modelAlias
                return obj
              })
            }
            _this_.allModelItems = data.map(item => {
              item.checked = item.modelStatus === 1
              return item
            })
          }
        }
      })
    },
    // 模块change
    moduleTypeChange (value, options) {
      if (value) {
        this.hasChildren = options.hasChildren
        if (value === 'commonFunctions' || value === 'applicationModule') {
          this.isAppPackage = true
        } else {
          this.isAppPackage = false
        }
        this.getAllModelItems(value)
      }
    },
    // 应用包确定验证
    beforeSave (list, ids) {
      if (list.length > 10) {
        this.$message('最多添加10个应用包')
        return false
      } else {
        return true
      }
    },
    appPackageValid (rule, value, callback) {
      if (this.form.subModels.length > 0) {
        callback()
      } else {
        callback(new Error('请选择应用包！'))
      }
    },
    // 选择应用包
    toSelectApp () {
      this.selectAppPackageShow = true
    },
    // 新增、编辑模块
    saveModule () {
      let _this_ = this
      if (!_this_.isAppPackage && _this_.hasChildren) {
        let selected = this.allModelItems
        if (selected.length === 0) {
          this.$message('请选择显示内容')
          return false
        } else {
          this.form.subModels = selected.map(item => {
            let obj = {}
            obj.classification = item.classification
            obj.modelAlias = item.modelAlias
            obj.refId = item.refId
            obj.modelStatus = item.checked ? 1 : 2
            return obj
          })
        }

        // 要显示的内容
        let showList = []
        this.form.subModels.forEach(model => {
          if (model.modelStatus === 1) {
            showList.push(model)
          }
        })
        if (showList.length === 0) {
          this.$message('请选择显示内容')
          return false
        }
      }
      if (_this_.isAppPackage) {
        this.form.subModels.filter(item => delete item.obj)
        if (this.form.subModels.length > 10) {
          this.$message('应用包最多选择10个')
          return false
        }
      }
      // console.log(this.form)
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$axios.post(this.submitURL, this.form).then(res => {
            if (res.status === 100) {
              _this_.$message({
                type: 'success',
                message: '保存成功',
                center: true
              })
              _this_.modelDialogVisible = false
              // 更新主页的list
              _this_.updateList()
            }
          })
        }
      })
    }
  },
  watch: {
    value (newValue) {
      this.modelDialogVisible = newValue
      this.$emit('change', newValue)
    },
    modelDialogVisible (newValue) {
      // v-model event
      this.$emit('input', newValue)
      if (newValue) {
        this.submitURL = this.isEdit ? updateModelURL : createModelURL
        this.moduleTitle = this.isEdit ? '编辑模块' : '新增模块'
        if (this.$refs['form']) {
          // 重置表单数据 resetFields无效
          this.form = {
            id: undefined,
            modelAlias: '',
            classification: undefined,
            subModels: []
          }
          this.isAppPackage = false
          this.hasChildren = false
          this.$nextTick(() => {
            this.$refs.form.clearValidate()
          })
        }
        if (this.isEdit) {
          this.form.id = this.appModule.id
          this.form.classification = this.appModule.classification
          this.form.modelAlias = this.appModule.modelAlias
          // 查询子模块
          const selectedOps = this.worktopModuleOps.filter(item => item.value === this.form.classification)
          this.moduleTypeChange(this.form.classification, selectedOps[0])
          this.form.subModels = this.appModule.childreds.map(alias => {
            let aliasItem = {
              modelAlias: alias.modelAlias,
              refId: alias.refId
            }
            return aliasItem
          })
        }
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.module-dialog-form {
  text-align: left;
  > .el-form-item {
    text-align: left;
  }
  .appPackage {
    width: 400px;
    ::v-deep .footer {
      width: 400px;
    }
  }
  .sub-list-group {
    display: flex;
    flex-wrap: wrap;
    .sub-list-item {
      height: 44px;
      padding-right: 16px;
      padding-left: 10px;
      border-radius: 4px;
      margin-right: 16px;
      width: 130px;
    }
    .sub-list-item:hover {
      box-shadow: 0px 2px 16px 0px rgba(27, 140, 242, 0.15);
    }
  }
  .sub-ghost {
    opacity: 0.5;
    background: rgba(27, 140, 242, 0.15);
    box-shadow: 0px 2px 16px 0px rgba(27, 140, 242, 0.15);
  }
}
</style>
