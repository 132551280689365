import axios from '@/axios/index'
// 初始化模块list
const initModelListURL = `${API_CONFIG.butlerBaseURL}workbench/initModelList`
// 获取工作台左右列表
const getDraggableListURL = `${API_CONFIG.butlerBaseURL}workbench/list`
// 保存工作台布局
const saveSortUrl = `${API_CONFIG.butlerBaseURL}workbench/saveSort`
// 获取模块子模块
const getAllModelItemsURL = `${API_CONFIG.butlerBaseURL}workbench/getModel`
// 保存模块
const createModelURL = `${API_CONFIG.butlerBaseURL}workbench/saveModel`
// 编辑模块
const updateModelURL = `${API_CONFIG.butlerBaseURL}workbench/updateModel`
// 隐藏模块
const deleteModelURL = `${API_CONFIG.butlerBaseURL}workbench/hiddenModel`
// 待办数量查询
const queryTodoCountURL = `${API_CONFIG.butlerBaseURL}workbench/countModels`
// 查询数据看板子模块的数据
const getDataKanBanSubDataURL = `${API_CONFIG.butlerBaseURL}workbench/countModels`

// 新查询数据看板子模块的数据
const getWorkOrderTypeCount = `${API_CONFIG.butlerBaseURL}workbench/getWorkOrderTypeCount`

// 返回值 true 表示工作台有新工单权限，跳转工单2.0。  false 跳转工单3.0
const getNewWorkOrderPermission = `${API_CONFIG.butlerBaseURL}workbench/getNewWorkOrderPermission`
// 返回 data{flag,regionId}用来判断这个用户是否存在工单3.0得权限
const get3WorkOrderPermission = () =>{
  return axios({
    method:'get',
    url:`${API_CONFIG.butlerBaseURL}workbench/getNewWorkOrderPermissionV3`,
  })
  // return new Promise((res)=>{
  //   res({data:{
  //     flag:true,
  //     regionId:'eb1e1ad8-85af-42d0-ba3c-21229be19009'
  //   },status:100})
  // })
}
export {
  getDraggableListURL,
  saveSortUrl,
  getAllModelItemsURL,
  createModelURL,
  updateModelURL,
  initModelListURL,
  queryTodoCountURL,
  deleteModelURL,
  getDataKanBanSubDataURL,
  getNewWorkOrderPermission,
  getWorkOrderTypeCount,
  get3WorkOrderPermission
}
