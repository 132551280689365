var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "worktop-container" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.lock",
              value: _vm.loadingVisible,
              expression: "loadingVisible",
              modifiers: { lock: true },
            },
          ],
          staticClass: "module",
          attrs: {
            "element-loading-text": "工作台加载中,请稍后...",
            "element-loading-spinner": "el-icon-loading",
          },
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "draggable",
                {
                  staticClass: "list-group",
                  attrs: {
                    tag: "ul",
                    list: _vm.leftList,
                    options: _vm.dragOptions,
                  },
                  on: { end: _vm.saveModuleSort },
                },
                _vm._l(_vm.leftList, function (element, index) {
                  return _c(
                    "div",
                    {
                      key: `${element.id}_${index}`,
                      class: ["list-group-item", { half: index > 1 }],
                    },
                    [
                      _c(element.componentName, {
                        tag: "component",
                        attrs: { appModule: element, leftIndex: index },
                        on: { clickNotice: _vm.clickNotice },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "center" },
            [
              _c(
                "draggable",
                {
                  staticClass: "list-group",
                  attrs: {
                    tag: "ul",
                    list: _vm.centerList,
                    options: _vm.dragOptions,
                  },
                  on: { end: _vm.saveModuleSort },
                },
                _vm._l(_vm.centerList, function (element, index) {
                  return _c(
                    "div",
                    {
                      key: `${element.id}_${index}`,
                      staticClass: "list-group-item",
                    },
                    [
                      _c(element.componentName, {
                        tag: "component",
                        attrs: { appModule: element, centerIndex: index },
                        on: { clickNotice: _vm.clickNotice },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "draggable",
                _vm._b(
                  {
                    staticClass: "list-group",
                    attrs: { list: _vm.rightList, tag: "ul" },
                    on: { end: _vm.saveModuleSort },
                  },
                  "draggable",
                  _vm.dragOptions,
                  false
                ),
                _vm._l(_vm.rightList, function (element, index) {
                  return _c(
                    "div",
                    {
                      key: `${element.id}_${index}`,
                      staticClass: "list-group-item",
                    },
                    [
                      _c(element.componentName, {
                        tag: "component",
                        attrs: { appModule: element, leftIndex: -1 },
                        on: { clickNotice: _vm.clickNotice },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ]
      ),
      _vm.leftList.length === 0 &&
      _vm.centerList.length === 0 &&
      _vm.rightList.length === 0 &&
      _vm.loadingVisible === false
        ? _c(
            "div",
            { staticClass: "empty-wrapper" },
            [
              _c("img", {
                attrs: { src: require("./components/images/moduleEmpty.png") },
              }),
              _c(
                "p",
                { style: { color: _vm.theme.worktop_minor_text_color } },
                [_vm._v("暂无模块，快去添加吧")]
              ),
              _c(
                "v-button",
                {
                  staticClass: "addbtn",
                  on: {
                    click: function ($event) {
                      _vm.moduleVisiable = true
                    },
                  },
                },
                [_vm._v("添加模块")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("module-dialog", {
        attrs: { isEdit: false, updateList: _vm.getDraggableList },
        model: {
          value: _vm.moduleVisiable,
          callback: function ($$v) {
            _vm.moduleVisiable = $$v
          },
          expression: "moduleVisiable",
        },
      }),
      _vm.noticeDialog
        ? _c("notice-view", {
            attrs: { id: _vm.noticeId, detailVisible: _vm.noticeDialog },
            on: {
              "update:detailVisible": function ($event) {
                _vm.noticeDialog = $event
              },
              "update:detail-visible": function ($event) {
                _vm.noticeDialog = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }