var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.modelDialogVisible,
        width: "700px",
        title: _vm.moduleTitle,
        "custom-class": "module-dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.modelDialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "module-dialog-form",
          attrs: { "label-width": "80px", model: _vm.form },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "模块",
                prop: "classification",
                rules: [
                  { required: true, message: "请选择模块", trigger: "change" },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: {
                  disabled: _vm.isEdit,
                  options: _vm.worktopModuleOps,
                  width: _vm.width,
                },
                on: { change: _vm.moduleTypeChange },
                model: {
                  value: _vm.form.classification,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "classification", $$v)
                  },
                  expression: "form.classification",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "模块名称",
                rules: [
                  {
                    required: true,
                    message: "请输入模块名称",
                    trigger: "blur",
                  },
                ],
                maxlength: 10,
                prop: "modelAlias",
              },
            },
            [
              _c("v-input", {
                attrs: { width: _vm.width, maxlength: 10 },
                model: {
                  value: _vm.form.modelAlias,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "modelAlias", $$v)
                  },
                  expression: "form.modelAlias",
                },
              }),
            ],
            1
          ),
          _vm.isAppPackage && _vm.hasChildren
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "应用包",
                    prop: "subModels",
                    rules: [
                      {
                        required: _vm.isAppPackage && _vm.hasChildren,
                        validator: _vm.appPackageValid,
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("chosenListPanel", {
                    key: "refId",
                    staticClass: "appPackage",
                    attrs: { textAs: "modelAlias", list: _vm.form.subModels },
                    on: {
                      "update:list": function ($event) {
                        return _vm.$set(_vm.form, "subModels", $event)
                      },
                      select: _vm.toSelectApp,
                    },
                  }),
                  _c("multi-select", {
                    attrs: {
                      title: "选择应用包",
                      isShow: _vm.selectAppPackageShow,
                      showAllSelectBtn: false,
                      searchUrl: _vm.getAppPackageListURL,
                      headers: _vm.appPackageHeader,
                      searchParams: _vm.appPackageSearchParams,
                      responseParams: _vm.appPackageResponseParams,
                      responseKey: _vm.appPackageResponseKey,
                      backFill: _vm.form.subModels,
                      "append-to-body": true,
                      beforeSave: _vm.beforeSave,
                    },
                    on: {
                      "update:isShow": function ($event) {
                        _vm.selectAppPackageShow = $event
                      },
                      "update:is-show": function ($event) {
                        _vm.selectAppPackageShow = $event
                      },
                      "update:backFill": function ($event) {
                        return _vm.$set(_vm.form, "subModels", $event)
                      },
                      "update:back-fill": function ($event) {
                        return _vm.$set(_vm.form, "subModels", $event)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "searchSlot",
                          fn: function () {
                            return [
                              _c("v-input", {
                                attrs: { label: "应用包名称", width: 160 },
                                model: {
                                  value:
                                    _vm.appPackageSearchParams.appPackageName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.appPackageSearchParams,
                                      "appPackageName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "appPackageSearchParams.appPackageName",
                                },
                              }),
                              _c("v-input", {
                                attrs: { label: "创建人", width: 160 },
                                model: {
                                  value: _vm.appPackageSearchParams.creater,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.appPackageSearchParams,
                                      "creater",
                                      $$v
                                    )
                                  },
                                  expression: "appPackageSearchParams.creater",
                                },
                              }),
                              _c("v-datepicker", {
                                attrs: {
                                  label: "创建时间段",
                                  startTime:
                                    _vm.appPackageSearchParams.createTimeStart,
                                  endTime:
                                    _vm.appPackageSearchParams.createTimeEnd,
                                  appendToBody: false,
                                  format: "YYYY-MM-DD HH:mm:ss",
                                  type: "rangedatetimer",
                                },
                                on: {
                                  "update:startTime": function ($event) {
                                    return _vm.$set(
                                      _vm.appPackageSearchParams,
                                      "createTimeStart",
                                      $event
                                    )
                                  },
                                  "update:start-time": function ($event) {
                                    return _vm.$set(
                                      _vm.appPackageSearchParams,
                                      "createTimeStart",
                                      $event
                                    )
                                  },
                                  "update:endTime": function ($event) {
                                    return _vm.$set(
                                      _vm.appPackageSearchParams,
                                      "createTimeEnd",
                                      $event
                                    )
                                  },
                                  "update:end-time": function ($event) {
                                    return _vm.$set(
                                      _vm.appPackageSearchParams,
                                      "createTimeEnd",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      845768390
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.isAppPackage && _vm.hasChildren
            ? _c(
                "el-form-item",
                { attrs: { label: "显示内容", required: "" } },
                [
                  _c(
                    "draggable",
                    {
                      staticClass: "sub-list-group",
                      attrs: {
                        tag: "ul",
                        list: _vm.allModelItems,
                        options: _vm.dragOptions,
                      },
                    },
                    _vm._l(_vm.allModelItems, function (sub, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "sub-list-item" },
                        [
                          _c("v-checkbox", {
                            attrs: { label: sub.modelAlias },
                            model: {
                              value: sub.checked,
                              callback: function ($$v) {
                                _vm.$set(sub, "checked", $$v)
                              },
                              expression: "sub.checked",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("v-button", {
            attrs: { type: "default", text: "取 消" },
            on: {
              click: function ($event) {
                _vm.modelDialogVisible = false
              },
            },
          }),
          _c("v-button", {
            staticStyle: { "margin-left": "10px" },
            attrs: { type: "success", text: "保 存" },
            on: { click: _vm.saveModule },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }