<template>
  <div class="worktop-container">
    <!-- <div class="top" :style="{'color' : theme.worktop_primary_text_color}">
      <img class="headerImg"
           :src="avatarSrc" />
      <span class="hello">你好，</span>
      <span class="name">{{ userName }}</span>
      <span class="welcome"> {{ `${date} 星期${weekDay}，欢迎回到智慧服务云平台工作台。`}}</span>
    </div> -->
    <div class="module"
      v-loading.lock="loadingVisible"
      element-loading-text="工作台加载中,请稍后..."
      element-loading-spinner="el-icon-loading"
    >
      <div class="left">
        <draggable class="list-group"
                   tag="ul"
                   :list="leftList"
                   :options="dragOptions"
                   @end="saveModuleSort">
          <!-- <transition-group type="transition"
                            name="flip-list"
                            v-if="list.length > 0"> -->
            <div :class="['list-group-item',{'half': index > 1}]"
                v-for="(element, index) in leftList"
                :key="`${element.id}_${index}`">
              <component :is="element.componentName"
                        :appModule="element" :leftIndex="index" @clickNotice="clickNotice"></component>
            </div>
          <!-- </transition-group> -->
        </draggable>
      </div>
       <div class="center">
        <draggable class="list-group"
                   tag="ul"
                   :list="centerList"
                   :options="dragOptions"
                   @end="saveModuleSort">
          <!-- <transition-group type="transition"
                            name="flip-list"
                            v-if="list.length > 0"> -->
            <div class="list-group-item"
                v-for="(element, index) in centerList"
                :key="`${element.id}_${index}`">
              <component :is="element.componentName"
                        :appModule="element" :centerIndex="index" @clickNotice="clickNotice"></component>
            </div>
          <!-- </transition-group> -->
        </draggable>
      </div>
      <div class="right">
        <draggable class="list-group"
                   :list="rightList"
                   v-bind="dragOptions"
                   tag="ul"
                   @end="saveModuleSort">
          <!-- <transition-group name="no"> -->
            <div class="list-group-item"
                v-for="(element,index) in rightList"
                :key="`${element.id}_${index}`">
              <component :is="element.componentName" :appModule="element" :leftIndex="-1" @clickNotice="clickNotice"></component>
            </div>
          <!-- </transition-group> -->
        </draggable>
      </div>
    </div>
    <div class="empty-wrapper" v-if="leftList.length === 0 && centerList.length === 0 && rightList.length === 0 && loadingVisible === false">
       <img src="./components/images/moduleEmpty.png"  />
       <p :style="{'color':theme.worktop_minor_text_color}">暂无模块，快去添加吧</p>
       <v-button class="addbtn" @click="moduleVisiable=true">添加模块</v-button>
    </div>
    <!-- <el-dialog class="loading-wrapper" :visible.sync="loadingVisible" :fullscreen="true" :modal="false">
      <div class="progress-wrapper"
          v-loading.fullscreen.lock="loadingVisible"
          element-loading-text="工作台加载中,请稍后..."
          element-loading-spinner="el-icon-loading"
          >
      </div>
    </el-dialog> -->
    <module-dialog v-model="moduleVisiable" :isEdit="false" :updateList="getDraggableList"/>
    <notice-view v-if="noticeDialog" :id="noticeId" :detailVisible.sync="noticeDialog"></notice-view>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import moment from 'moment'
import { initModelListURL, getDraggableListURL, saveSortUrl } from './api'
import { worktopModule } from './module'
import ModuleDialog from './components/ModuleDialog'
import NoticeView from '../operations/platformNotice/NoticeView.vue'

const chineseNumberMap = {
  0: '日',
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六'
}
export default {
  name: 'hello',
  components: {
    draggable,
    ModuleDialog,
    NoticeView
  },
  computed: {
    dragOptions () {
      return {
        animation: 0,
        group: 'description',
        ghostClass: 'ghost'
      }
    },
    userInfo () {
      return this.$store.getters.getUserInfo
    },
    theme () {
      return this.$store.state.theme
    }
  },
  provide () {
    return {
      updateList: this.getDraggableList
    }
  },
  created () {
    this.avatarSrc = this.userInfo.headPicName
    this.userName = this.userInfo.userName
    this.getDraggableList()
  },
  data () {
    return {
      noticeDialog: false,
      noticeId: undefined,
      avatarSrc: '',
      userName: '',
      date: moment().format('YYYY年MM月DD日'),
      weekDay: chineseNumberMap[moment().weekday()],
      leftList: [],
      centerList: [],
      rightList: [],
      initCount: 0,
      percentage: 10,
      loadingVisible: true,
      moduleVisiable: false
    }
  },
  methods: {
    clickNotice (row) {
      this.noticeId = row.id
      this.noticeDialog = true
    },
    // 获取模块list
    getDraggableList () {
      let _this_ = this
      _this_.$axios.get(getDraggableListURL).then(res => {
        if (_this_.loadingVisible) {
          _this_.increase()
        }
        if (res.status === 100) {
          const data = res.data
          if (data) {
            console.log(data)
            _this_.leftList = _this_.getComponentsByType(data.leftList)
            _this_.centerList = _this_.getComponentsByType(data.centerList)
            _this_.rightList = _this_.getComponentsByType(data.rightList)
            console.log( _this_.rightList)
            _this_.loadingVisible = false
            // _this_.loadingVisible = true
          } else {
            if (_this_.initCount < 1) {
              _this_.initModelList()
            }
          }
          _this_.initCount++
        }
      })
    },
    getComponentsByType (array) {
      if (array && array.length) {
        array.forEach(item => {
          worktopModule.filter(module => {
            if (item.classification === module.value) {
              item.componentName = module.component
            }
          })
        })
      }
      return array
    },
    // 初始化模块数据
    initModelList () {
      let _this_ = this
      _this_.$axios.get(initModelListURL).then(res => {
        if (res.status === 100) {
          const data = res.data
          if (data) {
            _this_.getDraggableList()
          }
        }
      })
    },
    // 保存模块布局
    saveModuleSort () {
      let postData = {
        leftList: this.leftList,
        centerList: this.centerList,
        rightList: this.rightList
      }
      let _this_ = this
      _this_.$axios.post(saveSortUrl, postData).then(res => {
        if (res.status === 100) {
          // 重新刷新列表
          _this_.getDraggableList()
        }
      })
    },
    // 进度条
    increase () {
      let _this_ = this
      if (this.percentage < 100) {
        this.percentage += 20
        setTimeout(function () {
          _this_.increase()
        }, 10)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.worktop-container {
  overflow: auto;
  .top {
    text-align: left;
    display: flex;
    align-items: flex-end;
    padding-bottom: 16px;
    .headerImg {
      height: 44px;
      width: 44px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
      border: 3px solid rgba(255, 255, 255, 1);
      border-radius: 100%;
    }
    .hello {
      padding-left: 24px;
      line-height: 50px;
      font-size: 36px;
      font-weight: 700;
    }
    .name {
      padding: 0 16px;
      font-size: 16px;
      line-height: 20px;
      border-right: 1px solid #aaaaaa;
    }
    .welcome {
      padding-left: 16px;
    }
  }
  .module {
    min-height: 100%;
    display: flex;
    margin-right:16px;
    .left {
      flex: 1;
      .list-group-item {
        padding: 0 16px 16px 0;
      }
    }
    .center {
      width: 500px;
      .list-group-item {
        padding: 0 16px 16px 0;
      }
    }
    .right {
      width: 500px;
      .list-group-item {
        padding-bottom: 16px;
      }
    }
    .list-group {
      min-height: 100%;
      .list-group-item {
        cursor: move;
        div:first-child {
          border-radius: 4px;
        }
      }
      .half {
        width: 50%;
        box-sizing: border-box;
        float: left;
      }
    }
  }
  .empty-wrapper {
   margin: 0 auto;
   padding-top:165px;
    img {
      width:672px;
      height: 365px;
    }
    .addbtn {
      width:120px;
      border-radius:18px;
    }
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .list-group {
    min-height: 100%;
  }

  .list-group-item {
    cursor: move;
    border-radius: 5px;
  }

  .list-group-item i {
    cursor: pointer;
  }
}
::v-deep {
  // .loading-wrapper {
  //   .el-dialog {
  //     background: transparent;
  //   }
  //   .el-dialog__header {
  //     display: none;
  //   }
  //   .el-dialog__body {
  //     height:100%;
  //     padding:0;
  //     overflow:hidden;
  //   }
  // }
  // .el-loading-mask {
  //   .el-loading-spinner{
  //     i {
  //       font-size:50px
  //     }
  //     .el-loading-text {
  //       font-size:40px;
  //       padding-top:10px;
  //     }
  //   }
  // }
}
</style>
