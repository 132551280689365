import { mapHelper } from 'common/utils'
const worktopModule = [{
  value: 'toDoList',
  text: '待办事项',
  hasChildren: true,
  component: resolve => require(['./components/todoPanel'], resolve)
}, {
  value: 'commonFunctions',
  text: '常用功能',
  hasChildren: true,
  component: resolve => require(['./components/ApplicationPackage'], resolve)
}, {
  value: 'dataKanBan',
  text: '数据看板',
  hasChildren: true,
  component: resolve => require(['./components/dataBoard'], resolve)
},
{
  value: 'quickOperation',
  text: '快捷操作',
  hasChildren: true,
  component: resolve => require(['./components/quickActions'], resolve)
},
{
  value: 'platformAnnouncement',
  text: '平台公告',
  hasChildren: false,
  component: resolve => require(['./components/platformNotice/Notice'], resolve)
}, {
  value: 'applicationModule',
  text: '应用模块',
  hasChildren: true,
  component: resolve => require(['./components/ApplicationPackage'], resolve)
}, {
  value:'spatialRetrieval',
  text:'空间检索',
  hasChildren: false,
  component: resolve => require(['./components/spatialRetrieval'],resolve)
}]
const {
  map: worktopModuleMap,
  setOps: setWorktopModuleOps
} = mapHelper.setMap(worktopModule)
export {
  worktopModule,
  worktopModuleMap,
  setWorktopModuleOps
}
